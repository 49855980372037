import React from "react";

const Divider = () => {
  return (
    <hr
      css={{
        height: 1,
        borderWidth: 0,
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      }}
    />
  )
}

export default Divider
