import React from "react"

import { Twitter, Github, Dribbble, Linkedin } from "../components/Icons"

export const networks = {
  socialNetworks: [
    {
      idx: 1,
      name: "Github",
      url: "https://github.com/graphmatth",
      icon: <Github />,
    },
    {
      idx: 2,
      name: "Twitter",
      url: "https://twitter.com/graphmatth",
      icon: <Twitter />,
    },
    {
      idx: 3,
      name: "Linkedin",
      url: "https://www.linkedin.com/in/matthieu-caron/",
      icon: <Linkedin />,
    },
  ],
}
