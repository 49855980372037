import React from "react"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/core"
import normalize from "normalize.css"
import Header from "../Header"
import Footer from "../Footer"
import SiteMetadata from "../../utils/site-metadata"

const Wrapper = styled.div`
  font-family: "Inter", sans-serif;
  max-width: 980px;
  display: block;
  padding: 0 1rem;
  margin: 0 auto;
`

export default function Layout({ children, noFooter }) {
  return (
    <>
      <Global
        styles={css`
          ${normalize}
          font-display: swap;
        `}
      />
      <SiteMetadata />
      <Wrapper>
        <Header />
        <div css={{ margin: "3rem auto", display: "block" }}>{children}</div>
        {!noFooter && <Footer />}
      </Wrapper>
    </>
  )
}
