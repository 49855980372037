import React from "react"
import { Helmet } from "react-helmet"

const SiteMetadata = () => {
  // Note: `location.href` isn't available on server-side so we must get it from `pathname`:
  // https://css-tricks.com/how-to-the-get-current-page-url-in-gatsby/#article-header-id-4
  const href = `https://matthieucaron.fr`

  return (
    <Helmet
      defer={false}
      defaultTitle={`Matthieu Caron - Développeur front-end à Bordeaux`}
    >
      <html lang="fr" />
      <link rel="canonical" href={href} />
      <meta name="docsearch:version" content="2.0" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />
      <meta
        name="description"
        content="Matthieu Caron, développeur front-end à Bordeaux"
      />
      <meta property="og:url" content={href} />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content="Matthieu Caron, développeur front-end à Bordeaux"
      />
      <meta property="og:locale" content="fr_FR" />
      <meta property="og:site_name" content="Matthieu Caron" />
      {/* <meta
        property="og:image"
        content={`https://matthieucaron.fr/matthieu-icon.jpg`}
      />
      <meta
        property="og:image:secure_url"
        content={`https://matthieucaron.fr/matthieu-icon.jpg`}
      /> */}
      <meta property="og:image:type" content={`image/jpeg`} />
      <meta property="og:image:alt" content="Logo Matthieu Caron" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="627" />
      <meta
        property="og:title"
        content="Matthieu Caron - Développeur front-end"
      />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@matthieucaron_" />
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:title"
        content="Matthieu Caron - Développeur front-end"
      />
      <meta
        name="twitter:description"
        content="Matthieu Caron, développeur front-end"
      />
      {/* <meta
        name="twitter:image"
        content="https://matthieucaron.fr/matthieu-icon.jpg"
      /> */}

  <script type="application/ld+json">
    {`
        {
          "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "Matthieu Caron",
          "url": "https://matthieucaron.fr"
        }
    `}
  </script>

    </Helmet>
  )
}

export default SiteMetadata
