import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 980px;
  margin: 0 auto;
  padding: 2rem 0rem 1rem 0rem;
`

const Logo = styled(Link)`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #000;
  display: block;
`

const TextLink = styled(Link)`
  color: #000;
  text-decoration: none;
  opacity: 0.75;

  &:hover {
    transition: all 0.2s ease-in-out;
    box-shadow: inset 0 -1px 0 #000001;
    opacity: 1;
  }
`

export default function Header() {
  return (
    <HeaderContainer>
      <Logo to="/" />
      {/* <nav>
        <TextLink
          activeStyle={{
            fontWeight: 700,
            opacity: 1,
            boxShadow: "inset 0 -1px 0 #000001",
          }}
          to="/a-propos"
        >
          À propos
        </TextLink>
      </nav> */}
    </HeaderContainer>
  )
}
