import React from "react"
import styled from "@emotion/styled"
import Divider from "../Divider"
import { networks } from "../../data/networks"

const FooterIcon = styled.a`
  display: block;
  transition: all 0.2s ease-in-out;
  padding: 1.5rem 0.75rem;
  margin: 0;
  opacity: 0.9;
  &:hover {
    opacity: 1;
    transform: scale(1.2);
  }
`

function SocialList({ url, icon }) {
  return (
    <FooterIcon href={url} rel="noreferrer" target="_blank">
      {icon}
    </FooterIcon>
  )
}

export default function Footer() {
  return (
    <footer css={{ marginTop: "3rem", marginBottom: "1rem" }}>
      <Divider />
      <div css={{ textAlign: "center", paddingTop: "0.5rem", opacity: 0.75 }}>
        <p css={{ margin: "0 auto" }}>
          {new Date().getFullYear()} — Matthieu Caron
        </p>
      </div>
      <div
        css={{
          padding: "0rem 0.5rem",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        {networks !== "undefined" &&
          networks.socialNetworks.map(network => {
            return (
              <SocialList
                key={network.idx}
                url={network.url}
                icon={network.icon}
              />
            )
          })}
      </div>
    </footer>
  )
}
